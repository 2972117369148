<template>
    <v-container class="bg-page" fluid fill-height justify-center align-center>
        <v-row justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <transition name="fade">
                    <v-card v-show="show" elevation="12">
                        <v-toolbar color="primary" class="white--text" flat>
                            <v-toolbar-title>{{ $t('enter_system') }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn v-if="!$vuetify.theme.dark" @click="setTheme(true)" icon>
                                <v-icon class="white--text">mdi-moon-waning-crescent</v-icon>
                            </v-btn>
                            <v-btn v-if="$vuetify.theme.dark" @click="setTheme(false)" icon>
                                <v-icon class="white--text">mdi-white-balance-sunny</v-icon>
                            </v-btn>
                            <v-menu open-on-hover offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon>
                                        <v-icon class="white--text">mdi-web</v-icon>
                                    </v-btn>
                                </template>
                                <v-list class="py-0" dense>
                                    <v-list-item @click="setLang('kk', true)">
                                        <v-list-item-title>{{ $t('kazakh') }}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="setLang('ru', true)">
                                        <v-list-item-title>{{ $t('russian') }}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="setLang('en', true)">
                                        <v-list-item-title>{{ $t('english') }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>
                        <v-form @submit.prevent="submit">
                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                <v-card-text class="px-6">
                                    <v-row>
                                        <v-col cols="12" class="pb-0">
                                            <ValidationProvider ref="email" rules="required|email|min:5|max:50"
                                                                v-slot="{ errors, valid }">
                                                <v-text-field v-model="email" type="email" :error-messages="errors"
                                                              :disabled="loading" prepend-icon="mdi-email"
                                                              :label="$t('email')" clearable></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" class="py-0">
                                            <ValidationProvider ref="password" rules="required|min:8|max:30"
                                                                v-slot="{ errors, valid }">
                                                <v-text-field v-model="password" :error-messages="errors"
                                                              :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                                              :type="passwordHidden ? 'password' : 'text'"
                                                              :disabled="loading" :label="$t('password')"
                                                              @click:append="() => (passwordHidden = !passwordHidden)"
                                                              prepend-icon="mdi-lock" clearable></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="px-6 pb-6">
                                    <!--
                                    <v-btn text :disabled="loading" :to="{ name: 'forgot'}" color="grey darken-2">{{
                                        $t('forgot_password') }}
                                    </v-btn>
                                    -->
                                    <v-spacer></v-spacer>
                                    <v-btn type="submit" color="primary" :loading="loading"
                                           :disabled="loading || invalid || !validated">{{ $t('sign_in') }}
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-form>
                    </v-card>
                </transition>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

    import {mapGetters} from "vuex"
    import {ValidationObserver, ValidationProvider} from "vee-validate"
    import {mask} from 'vue-the-mask'

    export default {
        name: "Login",
        directives: {
            mask
        },
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                email: null,
                password: null,
                remember: null,
                passwordHidden: true,
                loading: false,
                show: false,
            };
        },
        computed: {
            ...mapGetters(['lang']),
        },
        mounted() {
            this.show = true
        },
        methods: {
            setLang(langSelect) {
                let langLocale = localStorage.getItem('lang')
                document.documentElement.lang = langSelect ? langSelect : (langLocale ? langLocale : process.env.VUE_APP_I18N_LOCALE)
                if (typeof this.$i18n.locale !== 'undefined') {
                    this.$i18n.locale = document.documentElement.lang
                }
                this.$moment.locale(this.$i18n.locale)
                if (typeof this.$vuetify.lang.current !== 'undefined') {
                    this.$vuetify.lang.current = document.documentElement.lang
                }
            },
            async setTheme(themeSelect) {
                let localTheme = localStorage.getItem('themeDark')
                localTheme = localTheme === 'true' ? true : (localTheme === 'false' ? false : null)
                const mediaTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
                this.$vuetify.theme.dark = (themeSelect !== undefined ? themeSelect : (localTheme !== null ? localTheme : mediaTheme))
                localStorage.setItem('themeDark', this.$vuetify.theme.dark)
                document.documentElement.setAttribute('class', (this.$vuetify.theme.dark ? 'dark' : 'light'))
            },
            clear() {
                this.email = this.password = null;
                requestAnimationFrame(() => {
                    this.$refs.observ.reset();
                });
            },
            async submit() {
                var _this = this
                this.loading = true;
                await this.$auth
                    .login({
                        body: {
                            email: this.email,
                            password: this.password,
                        },
                        fetchUser: true
                    })
                    .then(res => {
                        if (this.$auth.check() && this.$auth.user() && this.$auth.user().settings) {
                            this.$store.dispatch('setSettings', this.$auth.user().settings)
                        }
                        this.$toastr.success(this.$t('you_have_successfully_logged_in'));
                    })
                    .catch(err => {
                        if (err && err.status !== 503) {
                            this.$toastr.error(this.$t('you_entered_the_wrong_data'));
                        }
                        if (err && err.body && err.body.message) {
                            if (err && err.status === 503) {
                                this.$toastr.error(this.$t('the_service_is_temporarily_unavailable_please_try_again_later'), this.$t('maintenance_mode'));
                            } else {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        }
                    });
                this.loading = false;
            }
        }
    }
</script>
